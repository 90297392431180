<template>
  <div class="container">
    <div v-if="!loading">
      <PersonalityTypeResult v-if="personalityType" :personality="personalityType" class="result" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { GET_PERSONALITY_TYPES } from '@/apollo/queries';
import PersonalityTypeResult from '@/components/PersonalityTypeResult.vue';

export default {
  name: 'PersonalityType',
  components: {
    PersonalityTypeResult,
  },
  setup() {
    const route = useRoute();
    const short_title = route.params.id;
    const { result, loading, error } = useQuery(GET_PERSONALITY_TYPES);
    const personalityTypes = computed(() => result.value?.personalityTypes ?? []);

    return {
      loading,
      error,
      personalityType: computed(
        () => personalityTypes.value.find(
          (t) => t.shortTitle.toLowerCase() === short_title,
        ) || null,
      ),
    };
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/breakpoints';
@import '@/styles/variables';

.container {
  margin: auto;
  padding: 1em;

  @include breakpoint(large) {
    width: 80%;
    padding: 3em;
  }
}
</style>