<template>
  <div class="container">
    <div v-if="result">
      <div v-for="personality in result.personalityTypes" :key="personality.title" class="personality">
        <router-link
          :to="{ name: 'PersonalityType', params: { id: personality.shortTitle.toLowerCase() } }"
          class="router-link"
        >
          {{ personality.title.substring(0, personality.title.length - 7) }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { useQuery } from '@vue/apollo-composable';
import { GET_PERSONALITY_TYPES } from '@/apollo/queries';

export default {
  name: 'PersonalityTypeList',
  setup() {
    const { result } = useQuery(GET_PERSONALITY_TYPES);

    return {
      result,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/breakpoints';
@import '@/styles/variables';

.container {
  margin: auto;
  padding: 1em;

  @include breakpoint(large) {
    width: 80%;
    padding: 3em;
  }
}

.personality {
  margin-bottom: 2em;
  font-size: 18px;
}
</style>