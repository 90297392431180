import gql from 'graphql-tag';

export const GET_PERSONALITY_TYPES = gql`
  query personalityTypes {
    personalityTypes {
      shortTitle
      title
      businessDescription
    }
  }
`;
