<template>
  <div>
    <div class="page">
      <div class="header">
        <h1>{{ title }}</h1>
        <img
          :src="require('@/assets/personalities/' + personality.shortTitle.toLowerCase() + '.png')"
          class="header__image"
        />
      </div>
      <div v-html="description" class="description"></div>
    </div>
    <div class="about-rst">
      <img class="logo--small" src="@/assets/rst-dots.svg" />
      <div>
        <h2>About Ready Set Team</h2>
        <p>
          We are a trusted partner and advisor, offering insights and guidance that
          empower businesses to create cohesive teams, improve retention,
          and enhance overall performance.
        </p>
      </div>
      <a href="https://readysetteam.io/"><div class="next">Visit site</div></a>
    </div>
  </div>
</template>

<script>
import { markdownParser } from '@/utils';

export default {
  name: 'PersonalityTypeResult',
  props: {
    personality: Object,
  },
  computed: {
    description() {
      return markdownParser(this.personality.businessDescription);
    },
    title() {
      return this.personality.title.substring(0, this.personality.title.length - 7);
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/breakpoints';
@import '@/styles/variables';

.page {
  color: $myTypeBlack;
  margin: auto;
  padding: 1em;
  border: 1px solid $myTypeBlack;
  text-align: left;

  @include breakpoint(medium) {
    width: 70%;
    padding: 3em;
  }

  .header {
    display: flex;
    margin-bottom: 2em;
    justify-content: space-between;
    align-items: flex-end;

    h1 {
      margin: 0;
      text-align: left;
      font-size: 1.7em;
      
      @include breakpoint(large) {
        font-size: 3em;
      }
    }

    &__image {
      height: 100px;
      width: 100px;
      
      @include breakpoint(medium) {
        height: 200px;
        width: 200px;
      }

      @include breakpoint(large) {
        height: 300px;
        width: 300px;
      }
    }
  }

  .description {
    text-align: left;
    line-height: 1.5em;
  }
}

.description ::v-deep li {
  margin-left: 1em;
}

.about-rst {
  margin: auto;
  margin-top: 2em;
  margin-bottom: 8em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid $myTypeBlack;
  text-align: left;
  gap: 0.5em;
  padding: 1.5em;
  background-color: $myTypeYellow;

  @include breakpoint(medium) {
    width: 70%;
    padding: 3em;
  }
}
</style>