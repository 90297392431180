<template>
  <div>
    <ProgressBar
        v-if="!result && globalQuestionIndex"
        :totalSteps="sectionLength * 4"
        :stepNumber="globalQuestionIndex"
      />
    <div class="questionnaire">
      <transition name="slide">
        <div v-if="questionnaireSection && show" class="questionLine" :key="currentQuestionIndex">
          <div class="choice" @click="nextQuestionLine(randomizedLine[0])">
            <p class="choice__text">{{ randomizedLine[0].text }}</p>
          </div>
          <span :style="{ textAlign: 'center' }">OR</span>
          <div class="choice" @click="nextQuestionLine(randomizedLine[1])">
            <p class="choice__text">{{ randomizedLine[1].text }}</p>
          </div>
        </div>
      </transition>
      <PersonalityTypeResult v-if="result" :personality="result" class="result" />
      <div v-if="!result" class="tips">
        <p>🙏 Tip: <span>{{ tips[tipIndex] }}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import PersonalityTypeResult from '@/components/PersonalityTypeResult.vue';
import ProgressBar from '@/components/ProgressBar.vue';

export default {
  name: 'Questionnaire',
  props: {
    email: String,
  },
  components: {
    PersonalityTypeResult,
    ProgressBar,
  },
  data() {
    return {
      show: true,
      currentQuestionIndex: 0,
      globalQuestionIndex: 0,
      questionnaireSection: null,
      submissionId: null,
      sectionLength: null,
      sectionAnswers: [],
      sectionResults: {},
      result: null,
      tips: [
        'You will not be able to go back and change your answer. Take your time!',
        'Pick the answer that you / your friends think describes yourself better',
        'Answer honestly. There are no right or wrong answers',
        'Try thinking more about your past experiences than how you would like to see yourself in the future',
        'Don\'t overthink. The answer you picked first is most likely the right one',
      ],
      tipIndex: 0,
    };
  },
  created() {
    this.$apollo.mutate({
      mutation: gql`mutation startQuestionnaire($email: String!) {
        startQuestionnaire(email: $email) {
          questionnaireSubmissionId
          firstQuestionnaireSection {
            id
            firstTrait
            secondTrait
            lines {
              id
              weight
              firstStatement {
                id
                relatedTrait
                text
              }
              secondStatement {
                id
                relatedTrait
                text
              }
            }
          }
        }
      }`,
      variables: { email: this.email },
    }).then((response) => {
      const res = response.data;
      this.questionnaireSection = res.startQuestionnaire.firstQuestionnaireSection;
      this.submissionId = res.startQuestionnaire.questionnaireSubmissionId;
      this.sectionLength = res.startQuestionnaire.firstQuestionnaireSection.lines.length;
    });
  },
  computed: {
    currentLine() {
      return this.questionnaireSection.lines[this.currentQuestionIndex];
    },
    randomizedLine() {
      const randomInt = Math.floor((Math.random() * 2));
      const line = this.currentLine;
      if (line && randomInt) {
        return [line.firstStatement, line.secondStatement];
      } 
      return [line.secondStatement, line.firstStatement];
    },
    lastSectionLine() {
      return this.currentQuestionIndex + 1 === this.questionnaireSection.lines.length;
    },
    lineWeight() {
      return this.questionnaireSection.lines[this.currentQuestionIndex].weight;
    },
    sectionWinner() {
      return Object.keys(this.sectionResults)
        .reduce((a, b) => (this.sectionResults[a] > this.sectionResults[b] ? a : b));
    },
  },
  methods: {
    nextQuestionLine(statement) {
      this.show = false;
      const answers = this.sectionAnswers;
      const answer = { questionnaireLineId: this.currentLine.id, chosenStatementId: statement.id };
      this.sectionAnswers = [...answers, answer];
      const currentScore = this.sectionResults[statement.relatedTrait] || 0;
      this.sectionResults[statement.relatedTrait] = currentScore + this.lineWeight;
      if (this.lastSectionLine) {
        this.getNextSection();
      }
      this.currentQuestionIndex += 1;
      this.globalQuestionIndex += 1;
      setTimeout(() => {
        this.show = true;
        this.tipIndex = Math.floor((Math.random() * this.tips.length));
      }, 500);
    },
    getNextSection() {
      this.$apollo.mutate({
        mutation: gql`mutation submitQuestionnaireSection(
          $resultTrait: CharacterTrait!,
          $sectionId: ID!,
          $questionnaireSubmissionId: UUID!,
          $sectionAnswers: [QuestionnaireSectionAnswer],
        ) {
          submitQuestionnaireSection(
            resultTrait: $resultTrait,
            sectionId: $sectionId,
            questionnaireSubmissionId: $questionnaireSubmissionId,
            sectionAnswers: $sectionAnswers,
          ) {
            questionnaireFinished
            nextSection {
              id
              firstTrait
              secondTrait
              lines {
                id
                weight
                firstStatement {
                  id
                  relatedTrait
                  text
                }
                secondStatement {
                  id
                  relatedTrait
                  text
                }
              }
            }
            personalityType {
              title
              businessDescription
              shortTitle
            }
          }
        }`,
        variables: {
          resultTrait: this.sectionWinner,
          sectionId: this.questionnaireSection.id,
          sectionAnswers: this.sectionAnswers,
          questionnaireSubmissionId: this.submissionId,
        },
      }).then((response) => {
        const result = response.data.submitQuestionnaireSection;
        this.questionnaireSection = result.nextSection;
        if (result.questionnaireFinished) {
          this.result = result.personalityType;
          this.$emit("onResultRecieved");
        }
        this.resetValues();
      }).catch((error) => {
        this.error = error;
      });
    },
    resetValues() {
      this.sectionResults = {};
      this.currentQuestionIndex = 0;
      this.sectionAnswers = [];
      this.error = null;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/breakpoints';
@import '@/styles/transitions';
@import '@/styles/variables';

.questionnaire {
  position: relative;
  min-height: 49vh;
  padding-bottom: 2em;

  @include breakpoint(medium) {
    min-height: 20em;
  }

  @include breakpoint(large) {
    min-height: 18em;
  }
}

.questionLine {
  position: absolute;
  left: 0;
  right: 0;
  display: grid;
  grid-template-rows: 16em -1px 16em;
  align-items: center;
  justify-content: center;

  @include breakpoint(medium) {
    grid-template-rows: 16em 5px 16em;
  }

  @include breakpoint(medium) {
    grid-template-columns: 23em 20px 23em;
    grid-template-rows: 15em;
    column-gap: 0;
  }

  @include breakpoint(large) {
    grid-template-columns: 28em 50px 28em;
    grid-template-rows: 15em;
    column-gap: 10px;
  }

  .choice {
    display: flex;
    padding: 0.5em 1em;
    margin: 1em;
    min-height: 4em;
    background-color: $myTypeLightGray;
    line-height: 1.5em;
    border: 2px solid $myTypeBlack;
    color: $myTypeBlack;
    opacity: 0.8;
    cursor: pointer;

    &:hover {
      background-color: $myTypeLightPurple;
    }

    // effect of a pressed btn
    &:active {
      transform: scale(0.95);
    }

    @include breakpoint(large) {
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }

    @include breakpoint(medium) {
      min-height: 5em;
      padding: 1em;
      font-size: 1.5em;
      line-height: 1.3em;
    }

    &__text {
      margin: auto;
      font-size: 0.8em;
      font-weight: 800;
    }
  }
}

.result {
  margin: 1em;

  @include breakpoint(medium) {
    margin: auto;
    margin-top: 3em;
  }
}

.tips {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  text-align: center;
}
</style>
