import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';

const apolloHttpLink = createHttpLink({
  uri: process.env.VUE_APP_MEETCH_API_URL,
});

const apolloAuthContext = setContext(async (_, { headers }) => {
  const locale = localStorage.getItem('lang');
  return {
    headers: {
      ...headers,
      'Accept-Language': locale,
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const apolloClient = new ApolloClient({
  link: apolloAuthContext.concat(apolloHttpLink),
  cache: new InMemoryCache(),
  defaultOptions,
});

export default apolloClient;
