<template>
  <img class="logo" alt="RST logo" src="./assets/rst-logo.svg" @click="() => reload()">
  <br>
  <router-link v-if="currentRouteName === 'Home'" to="/personality-types" class="router-link">
    Personality Types
  </router-link>
  <router-view :key="updateKey" />
  <div class="footer">
    <img class="logo--small" alt="RST logo" src="./assets/rst-dots.svg" @click="() => reload()">
    <p>Ready Set Team © 2023</p>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from "vue-router";
import apolloClient from '@/apollo';
import { provideApolloClient } from '@vue/apollo-composable';

export default {
  name: 'Ready Set Team assessment',
  setup() {
    provideApolloClient(apolloClient);
    const route = useRoute();
    const currentRouteName = computed(() => {
      return route.name;
    });

    return {
      currentRouteName,
    }
  },
  data() {
    return {
      updateKey: 0,
    };
  },
  methods: {
    reload() {
      this.$router.push({
        name: 'Home',
      });
      this.updateKey += 1;
    },
  }
}
</script>

<style lang='scss'>
@import '@/styles/breakpoints';
@import '@/styles/index';
@import '@/styles/variables';

#app {
  min-height: 100vh;
  font-family: 'NunitoSans', -apple-system, 'Roboto', 'Open Sans', Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

@font-face {
  font-family: 'YasevaOne'; /* This is the name you'll use to reference the font */
  src: url('@/assets/fonts/YesevaOne-Regular.ttf') format('truetype'); /* Adjust the path based on your project's structure */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans'; /* This is the name you'll use to reference the font */
  src: url('@/assets/fonts/NunitoSans.ttf') format('truetype'); /* Adjust the path based on your project's structure */
  font-weight: normal;
  font-style: normal;
}
</style>
