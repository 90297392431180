<template>
  <div>
    <p class="label">{{ label }}</p>
    <div class="column">
      <input type="text" @input="$emit('emailChanged', $event.target.value)" />
      <p v-if="error" class="error">{{ error }}</p>
      <div class="next" @click="$emit('clickNext')">Next ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioButtons',
  props: {
    label: String,
    error: String,
  },
  emits: ['emailChanged', 'clickNext'],
}
</script>

<style lang="scss" scoped>
@import '@/styles/breakpoints';
@import '@/styles/index';
@import '@/styles/variables';

.label, .error {
  text-align: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  width: 70%;
  height: 3em;
  border: 2px solid $myTypeBlack;
  padding: 0 1em;
  color: $myTypeBlack;

  &:focus {
    outline: none;
  }
}

img {
  padding-left: 1em;

  &:hover {
    cursor: pointer;
  }
}
</style>
