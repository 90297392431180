import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  cache,
  uri: process.env.VUE_APP_MEETCH_API_URL,
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})

const app = createApp(App).use(apolloProvider).use(router);
app.mount('#app');

export default apolloProvider;
