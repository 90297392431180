<template>
  <div>
    <p>{{ label }}</p>
    <label v-for="value in values" :key="value" class="radio-item">{{ value }}
      <input type="radio" checked="checked" name="radio">
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioButtons',
  props: {
    label: String,
    values: Array,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/breakpoints';
@import '@/styles/index';
@import '@/styles/variables';

input[type='radio'] {
  display: none;
}

.radio-item {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1.1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
}

.radio-item:hover input ~ .checkmark {
  background-color: white;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-item input:checked ~ .checkmark:after {
  display: block;
}

.radio-item .checkmark:after {
  top: 5px;
	left: 5px;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: black;
}
</style>
