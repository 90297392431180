import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/Home.vue';
import PersonalityTypeList from '@/components/PersonalityTypeList.vue';
import PersonalityType from '@/components/PersonalityType.vue';

const routes = [
  {
    path: '/',
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/result',
        name: 'TestResult',
        component: Home,
      },
      {
        path: '/questionnaire',
        name: 'Questionnaire',
        component: Home,
      }
    ],
  },
  {
    path: '/personality-types',
    name: 'PersonalityTypeList',
    component: PersonalityTypeList,
  },
  {
    path: '/personality-types/:id',
    name: 'PersonalityType',
    component: PersonalityType,
    props: true,
  },
];

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});