<template>
  <div class="container">
    <h1>{{ header }}</h1>
    <p v-if="step == 0 || isResultReceived">{{ intro }}</p>
    <div class="transition-container">
    <transition name="slide" :class="{'step-box': step != 2 && step != 3 }">
      <component
        :is="currentComponent"
        :key="step"
        v-bind="currentProps"
        @clickNext="nextStep"
        @emailChanged="setEmail"
        @onResultRecieved="changeHeadings"
      >
      </component>
    </transition>
    </div>
    <div v-if="step && step <= 1" class="next" @click="nextStep">
      {{ step == 1 ? 'Start' : 'Next' }}
    </div>
  </div>
</template>

<script>
import Questionnaire from '@/components/Questionnaire.vue';
import RadioButtons from '@/components/RadioButtons.vue';
import TextInput from '@/components/TextInput.vue';
import { shallowRef } from 'vue';

export default {
  name: 'Home',
  components: {
    RadioButtons,
    TextInput,
  },
  data() {
    return {
      header: "Welcome to the Ready Set Team assessment",
      intro: "This assessment will identify your key personality and working style traits, and how these traits relate to other people’s. This information will help you improve your quality of working life and collaborate better with others.",
      components: [
        shallowRef(TextInput),
        <div>
          <p style="text-align: center">
            You are about to start a personality assessment. In each step you will be presented with 2 options.
            Choose the one that describes you in most cases. You will see each step once.
            It will take about 10 minutes to compete the assessment.
          </p>
        </div>,
        shallowRef(Questionnaire),
      ],
      currentComponent: shallowRef(TextInput),
      step: 0,
      email: "",
      error: "",
      isResultReceived: false,
    };
  },
  computed: {
    stepProps() {
      return [
        { label: "Please enter your e-mail to get started:", error: this.error },
        {},
        { email: this.email },
      ]
    },
    currentProps() {
      return this.stepProps[this.step];
    },
  },
  methods: {
    emailValid() {
      const re = /\S+@\S+\.\S+/;
      return re.test(this.email);
    },
    setEmail(value) {
      this.email = value;
    },
    nextStep() {
      this.error = "";
      if (this.step == 0) {
        if (!this.emailValid()) {
          this.error = "Please provide a valid email";
          return;
        }
        this.$router.push({
          name: 'Questionnaire',
        });
        this.header = "Get ready!";
      }
      if (this.step == 1) {
        this.header = "Choose one option:";
      }
      this.step = this.step <= 2 ? this.step + 1 : null;
      this.currentComponent = this.components[this.step];
    },
    changeHeadings() {
      this.$router.push({
        name: 'TestResult',
      });
      this.header = "All done!";
      this.intro = "Here is your result. What's next? Book a meeting with us to confirm your results and learn how you can use them to improve your team.";
      this.isResultReceived = true;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/breakpoints';
@import '@/styles/index';
@import '@/styles/transitions';
@import '@/styles/variables';

.container {
  margin: auto;
  min-height: 40vh;
  margin-top: 5%;
  padding-bottom: 2em;
  overflow: hidden;

  @include breakpoint(small) {
    margin-top: 8%;
  }

  @include breakpoint(medium) {
    max-width: 92%;
    margin-top: 8%;
    padding-bottom: 0;
  }
    
  @include breakpoint(large) {
    max-width: 80%;
    margin-top: 3%;
    padding-bottom: 0;
  }
}
.transition-container {
  min-height: 15em;
}

.step-box {
  position: absolute;
  left: 0;
  right: 0;
  padding: 1em;
  margin: auto;
  text-align: left;

  @include breakpoint(medium) {
    max-width: 70%;
  }

  @include breakpoint(large) {
    max-width: 30%;
  }
}

.next {
  margin: auto;

  &:hover {
    cursor: pointer;
  }
}

h1 {
  margin: auto;
  margin-bottom: 0.5em;
  font-family: 'YasevaOne';
  font-weight: 400;
  font-size: 28px;

  @include breakpoint(small) {
    max-width: 90%;
    font-size: 33px;
  }

  @include breakpoint(medium) {
    font-size: 3em;
  }

  @include breakpoint(large) {
    max-width: 100%;
    font-size: 4em;
  }
}

p {
  margin: auto;
  max-width: 95%;
  text-align: center;
}
</style>
