<template>
  <div class="stepper">
    <div v-for="index in totalSteps" :key="index">
      <div
          :class="{ completed: stepNumber + 1 > index, remaining: stepNumber + 1 <= index }"
          class="step"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    stepNumber: {
      type: Number,
      default: 1,
    },
    totalSteps: {
      type: Number,
      default: 32,
    },
  },
  methods: {
    setStep(index) {
      this.$emit('setStep', index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.stepper {
  position: absolute;
  top: 0;
  width: 100%;
  display: grid;
  grid-auto-flow: column;

  .current {
    background-color: $myTypeLightGray;
  }

  .step {
    min-height: 0.5em;
  }

  .step.completed {
    background-color: white;
  }

  .completed {
    background-color: $myTypeRed;
  }

  .remaining {
    background-color: white;
    opacity: 0.4;
  }
}
</style>
